@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/font-awesome.min.css');
@import url('brand.css');
@import url('index.css');

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--light-one);
}

.App-link {
  color: var(--light-highlight);
}

.full-width-image {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
}

.banner-image-text {
  position: relative;
  margin-bottom: 20px;
}

.banner-image-text h1 {
  position: absolute;
  top: 0.8vw;
  /* top: 15px; */
  left: 50%;
  transform: translateX(-50%); 
  color: var(--white); 
  text-shadow: 4px 4px 8px black; 
  /* font-size: 5rem; */
  font-size: 8vw;
  z-index: 1;
  /* transition: all 0.3s ease;  */
}

.responsive-text {
  margin-left: 50px;
  margin-right: 50px;
}

h2 {
  margin-bottom: 20px;
}

.responsive-grid {
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: top;
  justify-content: space-between; 
  gap: 20px; 
}

.responsive-grid.footer-grid {
  margin-left: 0px;
  margin-right: 0px;
}

.responsive-grid.footer-grid > * {
  flex: 1;
  min-width: 400px;
  max-width: 420px;
  box-sizing: border-box;
}

div.week-weather {
  margin-top: 8px;
}

#footer-contact {
  min-width: 180px;
  max-width: 180px;
  margin-top: 18px;
}

#footer-current-weather {
  min-width: 220px;
  max-width: 220px;
}

.responsive-grid > * {
  flex: 1;
  min-width: 300px;
  box-sizing: border-box;
}

div.croppedImage {
  width: 100%; /* Ensure the div fills its parent width */
  height: 300px; /* Set a fixed height for the div */
  overflow: hidden; /* Hide any overflow from the image */
  position: relative;
  border: 1px solid var(--dark-accent);
  padding: 8px; 
  box-sizing: border-box; /* Ensure padding doesn't affect the size */
  background-color: var(--light-accent);
  cursor: zoom-in;
}

img.croppedImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Make the image fill the width of the container */
  height: 100%; /* Make the image fill the height of the container */
  object-fit: cover; /* Ensures the image fills the frame, cropping if necessary */
}

img.croppedImage:hover {
  opacity: 0.3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.modal-image {
  max-width: 100%;
  border-radius: 8px;
  max-height: 80vh; /* Ensure image doesn't overflow the screen */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: var(--light-accent);
  cursor: pointer;
}

div.info-box {
  width: 290px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.info-text {
  margin-top: 10px;
  transition: color 0.3s ease; 
}

div.info-text:hover {
  color: var(--dark-highlight); 
  cursor: pointer;
}

div.space-above {
  margin-top: 53px;
}

div.small-space-above {
  margin-top: 20px;
}

div.error-message {
  border: 2px solid var(--error-color);
  background-color: var(--error-background);
  color: var(--error-color); 
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px; 
  margin-bottom: 20px;
}

.responsive-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center; 
  gap: 20px;
}

div.med-field {
  flex-basis: 400px;
  /* max-width: 100%; */
  /* box-sizing: border-box; */
}

button.btn-primary-branded {
  background-color: var(--light-highlight);
  border: var(--dark-highlight);
  color: var(--dark);
  font-size: 24px;
  padding: 10px 20px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

button.btn-primary-branded:hover {
  /* background-color: var(--dark-highlight); */
  background-color: var(--light-highlight);
  color: var(--dark);
  border-radius: 30px;
  font-size: 28x; 
  font-weight: bold; 
  box-shadow: 0px 4px 8px var(--dark-translucent);
}

div.week-column-booked {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100px;
  opacity: 0.5;
  padding-left: 5px;
  padding-right: 5px;
}

div.week-column-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100px;
  padding-left: 5px;
  padding-right: 5px;
}

input[type="checkbox"]:checked {
  accent-color: var(--light-highlight);
}

div.month-container {
  background-color: var(--dark-highlight-translucent);
  padding: 10px; 
  border-radius: 5px;
}

div.month-container h4 {
  background-color: var(--dark-highlight);
  color: var(--white);
  padding: 5px;
  margin: 0; 
  border-radius: 3px;
}

div.month-box {
  display: flex;
  gap: 5px;
  margin: 0 auto; 
  width: fit-content; 
  justify-content: center;
}

.calendar-grid {
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  gap: 20px; 
}

.calendar-grid > * {
  flex: 1;
  min-width: 350px;
  max-width: 350px;
  box-sizing: border-box;
}

div.calendar-month {
  width: 200px;
}

h3.year {
  background-color: var(--dark-translucent);
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  color: var(--white);
  display: inline-block;
}

#footer {
  background-color: var(--light-two);
  padding: 20px;
}

.stacked-buttons {
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
  align-items: flex-start; /* Left-align the buttons */
  gap: 10px; /* Optional: Add space between buttons */
}

div.footer-column {
  align-items: left;
}

.current-weather {
  /* color: var(--dark); */
  display: flex;
  flex-direction: row;
}

#weather-current-summary-container {
  display: flex;
  margin-top: 18px;
  margin-left: 10px;
  /* align-items: center;  */
}

#weather-current-summary > * {
  margin-bottom: 8px;
}

/* Remove margin from the last item to avoid extra space */
#weather-current-summary > *:last-child {
  margin-bottom: 0;
}

.weather-icon {
  width: 100px;
  height: 100px;
}

.weather-icon-small {
  width: 50px;
  height: 50px;
}

#current-temp {
  position: relative;
  top: -12px;
}

#current-temp-rainy {
  position: relative;
  top: 0px;
}

span.temp-small {
  font-size: 12px;
}

.week-weather {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 10px;
}

#weather-current-summary {
  text-align: left;
  line-height: 1.0;
}

.wind-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* .wind-speed {
  font-size: 1.2em;
} */

.wind-direction {
  font-size: 1.5em;
  transition: transform 0.3s;
}

div.map {
  height: 1000px;
  width: 100%;
  margin-bottom: 30px;
}

a {
  color: var(--light-highlight);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline; 
  color: var(--light-highlight); 
}

.tide-times {
  text-align: left !important;
  max-width: 240px !important;
  min-width: 240px !important;
  margin-left: 8px;
}

.tide-label-next {
  font-weight: bold;
}

div.bookings-info {
  margin-top: 20px;
}

.week-info {
  margin: 10px;
  border: 1px solid black;
  border-radius: 10px;
  border-width: 2px;
  box-shadow: 2px 2px 8px var(--dark-translucent);
  padding: 10px;
  transition: transform 0.25s ease, box-shadow 0.25s ease, border-radius 0.25s ease;
}

.week-info p {
  margin: 5px 0; /* Adjust the vertical space between <p> elements */
}

.week-info.past {
  background-color: var(--dark-accent);
  color: var(--light-one);
}

.week-info.present {
  background-color: var(--light-highlight);
  color: var(--light-two);
  border-color: var(--dark-translucent);
  box-shadow: 2px 2px 22px var(--dark-translucent);
  border-width: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.week-info.available {
  background-color: var(--dark-highlight);
  color: var(--light-one);
}

.week-info.booked {
  background-color: var(--error-background);
  color: var(--error-color);
}

.week-info:hover {
  transform: scale(1.1);
  border-radius: 50px;
  box-shadow: 2px 2px 8px 4px var(--dark-translucent);
  cursor: pointer;
}

.form-container {
  margin-left: 50px;
  margin-right: 50px;
}

.medium-field {
  max-width: 300px;
}

.narrow-field {
  max-width: 150px;
}

.very-narrow-field {
  max-width: 80px;
}

.centered-input {
  text-align: center;
} 

form.space-above {
  margin-top: 20px;
}

div.infobox-body {
  margin-top: 10px;
}

@media only screen and (max-width: 620px) {
  /* For mobile phones: */
  
  .responsive-grid {
    margin-left: 20px;
    margin-right: 20px;
  }

  .responsive-text {
    margin-left: 10px;
    margin-right: 10px;
  }

  div.map {
    height: 500px;
  }

  #footer {
    background-color: var(--light-two);
    padding-left: 5px;
    padding-right: 5px;
  }

  .form-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

