:root {
  --dark: black; /* Vibrant ocean blue */
  --dark-translucent: rgba(29, 111, 147, 0.8);
  --light-highlight: #58d1ec; /* Bright turquoise blue */
  --light-highlight-translucent: rgba(88, 209, 236, 0.5);
  --dark-highlight: #4ec2a5; /* Bold sea green */
  --dark-highlight-translucent: rgba(78, 194, 165, 0.5);
  --light-one: #fff8e6; /* Soft, sandy off-white */
  --light-two: #ffedbf; /* Sunny yellow */
  --white: #ffffff; /* Pure white */
  --dark-accent: #a1b7b8; /* Lightened, lively gray-blue */
  --light-accent: #aef3e2; /* Bright mint green */
  --error-background: rgba(255, 69, 58, 0.15); /* More visible coral red */
  --error-color: #ff453a; /* Bright coral for error text */
  --pin-color: #FF6F61;
}

@font-face {
  font-family: Euclid Circular A;
  src: url("./fonts/EuclidCircularA-Regular-WebXL.woff2") format('woff2');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Euclid Circular A;
  src: url("./fonts/EuclidCircularA-Medium-WebXL.woff2") format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Brand Value Serif;
  src: url("./fonts/MongoDBValueSerif-Regular.woff2") format('woff2');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Brand Value Serif;
  src: url("./fonts/MongoDBValueSerif-Medium.woff2") format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Brand Value Serif;
  src: url("./fonts/MongoDBValueSerif-Bold.woff2") format('woff2');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Source Code Pro;
  src: url("./fonts/SourceCodePro-Regular.ttf") format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Source Code Pro;
  src: url("./fonts/SourceCodePro-Medium.ttf") format('truetype');
  font-weight: 500;
  font-display: swap;
}